<template>
  <div class="standalone-hosting-section">
    <div class="initial-prompt-section">
      <div v-if="showOr" class="divider-container">
        <hr class="divider">
        <span>Or</span>
        <hr class="divider">
      </div>
      <div>
        <div class="mt-3">
          <b-link
            class="standalone-hosting-link"
            aria-label="Standalone Hosting Link"
            @click="$emit('show-standalone-hosting-modal')"
          >
            <b-icon icon="globe2" />
            {{ "Use My Own Domain" }}
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StandaloneHostingSection',
  props: {
    showOr: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
// Palette
$light-grey: #918F8F;

.standalone-hosting-section {

  .standalone-hosting-link {
    color: $ct-ui-color-10;
    text-decoration: underline;
  }
  .initial-prompt-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .divider-container {
      display: grid;
      grid-template-columns: 2fr auto 2fr;
      gap: 0.63em;
      align-items: center;
      justify-content: center;
      font-weight: $ct-ui-font-weight-7;
      width: 100%;
      max-width: 22em;
      margin-top: 2.5em;
      margin-bottom: 2em;

      hr.divider {
        width: 100%;
        border-color: $light-grey;
      }
    }
  }
}
</style>
